import { SimpleValueCard } from 'modules/analytics/components/Cards/SimpleValueCard';
import { Section } from 'modules/analytics/components/common/layout/Section/Section';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useInventoryCountQuery } from './gql/gql.generated';

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
  max-width: 225.8px;
`;

export const FleetSection = () => {
  const { t } = useTranslation('newHome');

  const { loading, data } = useInventoryCountQuery();

  return (
    <Section title={t('fleet.title')} subtitle="" actionElement={null}>
      <SectionRow>
        <SimpleValueCard
          loading={loading}
          title={t('fleet.connectedAssets')}
          value={data?.viewer?.workspace?.totalAssets ?? 0}
          unit="assets"
          hideNoTrend
        />
      </SectionRow>
    </Section>
  );
};
