import type * as Types from '../../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InventoryCountQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type InventoryCountQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    workspace?: {
      __typename?: 'Group';
      id?: string | null;
      totalAssets?: number | null;
    } | null;
  } | null;
};

export const InventoryCountDocument = gql`
    query InventoryCount {
  viewer {
    id
    workspace {
      id
      totalAssets
    }
  }
}
    `;

/**
 * __useInventoryCountQuery__
 *
 * To run a query within a React component, call `useInventoryCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useInventoryCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InventoryCountQuery,
    InventoryCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InventoryCountQuery, InventoryCountQueryVariables>(
    InventoryCountDocument,
    options,
  );
}
export function useInventoryCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InventoryCountQuery,
    InventoryCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InventoryCountQuery, InventoryCountQueryVariables>(
    InventoryCountDocument,
    options,
  );
}
export type InventoryCountQueryHookResult = ReturnType<
  typeof useInventoryCountQuery
>;
export type InventoryCountLazyQueryHookResult = ReturnType<
  typeof useInventoryCountLazyQuery
>;
export type InventoryCountQueryResult = Apollo.QueryResult<
  InventoryCountQuery,
  InventoryCountQueryVariables
>;
